<template>
  <img v-if="isShow" :src="iconType" :alt="type" @click="action" />
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    action: { type: Function, default: () => {} },
    type: { type: String, default: () => 'edit' },
    access: { type: Array, default: () => [] }
  },
  computed: {
    ...mapGetters(['getRole']),
    iconType: function() {
      return require(`../assets/icon/icon-${this.type}.svg`)
    },
    isShow: function() {
      return this.access.some(el => el === this.getRole)
    }
  }
}
</script>
