var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',[_c('b-form-group',{staticClass:"text-left",attrs:{"id":"input-group-oldPassword","label":_vm.$t('profile_info_change_oldPassword'),"label-for":"oldPassword"}},[_c('b-form-input',{attrs:{"id":"oldPassword","type":"password","state":_vm.validateState('oldPassword')},model:{value:(_vm.$v.form.oldPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.oldPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.oldPassword.$model"}}),(_vm.$v.form.oldPassword.$dirty && _vm.$v.form.oldPassword.$invalid)?[(!_vm.$v.form.oldPassword.alphaNumRegex)?_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('profile_info_confirm_password_alphanum_error'))+" ")]):_vm._e(),(!_vm.$v.form.oldPassword.minLength)?_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('profile_info_confirm_password_min_length_error'))+" ")]):_vm._e(),(!_vm.$v.form.oldPassword.required)?_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('profile_info_confirm_password_required_error'))+" ")]):_vm._e()]:_vm._e()],2),_c('b-form-group',{staticClass:"text-left",attrs:{"id":"input-group-newPassword","label":_vm.$t('profile_info_change_newPassword'),"label-for":"newPassword"}},[_c('b-form-input',{attrs:{"id":"newPassword","type":"password","state":_vm.form.confirmPassword.length
          ? _vm.$v.form.confirmPassword.sameAsNewPassword
          : _vm.validateState('newPassword')},model:{value:(_vm.$v.form.newPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.newPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.newPassword.$model"}}),(_vm.$v.form.newPassword.$dirty && _vm.$v.form.newPassword.$invalid)?[(!_vm.$v.form.newPassword.alphaNumRegex)?_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('profile_info_confirm_password_alphanum_error'))+" ")]):_vm._e(),(!_vm.$v.form.newPassword.minLength)?_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('profile_info_confirm_password_min_length_error'))+" ")]):_vm._e(),(!_vm.$v.form.newPassword.required)?_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('profile_info_confirm_password_required_error'))+" ")]):_vm._e()]:_vm._e()],2),_c('b-form-group',{staticClass:"text-left",attrs:{"id":"input-group-confirm-newPassword","label":_vm.$t('profile_info_confirm_newPassword'),"label-for":"newPassword"}},[_c('b-form-input',{attrs:{"id":"confirmPassword","type":"password","state":_vm.validateState('confirmPassword')},model:{value:(_vm.$v.form.confirmPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.confirmPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.confirmPassword.$model"}}),(
        _vm.$v.form.confirmPassword.$dirty && _vm.$v.form.confirmPassword.$invalid
      )?[(!_vm.$v.form.confirmPassword.alphaNumRegex)?_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('profile_info_confirm_password_alphanum_error'))+" ")]):_vm._e(),(!_vm.$v.form.confirmPassword.minLength)?_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('profile_info_confirm_password_min_length_error'))+" ")]):_vm._e(),(!_vm.$v.form.confirmPassword.required)?_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('profile_info_confirm_password_required_error'))+" ")]):_vm._e()]:_vm._e()],2),(_vm.$v.form.$anyError)?[(
        _vm.$v.form.newPassword.$dirty && !_vm.$v.form.newPassword.sameAsOldPassword
      )?_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('profile_info_change_newPassword_error'))+" ")]):_vm._e(),(
        _vm.$v.form.confirmPassword.$dirty &&
          !_vm.$v.form.confirmPassword.sameAsNewPassword
      )?_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('profile_info_confirm_newPassword_error'))+" ")]):_vm._e()]:_vm._e(),_c('hr'),_c('div',{staticClass:"ModalBtn"},[_c('b-button',{staticClass:"mr-3 ml-auto success",on:{"click":_vm.passwordChange}},[_vm._v(" "+_vm._s(_vm.$t('main_page_sub_nav_save_result'))+" ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }