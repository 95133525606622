<template>
  <div>
    <div class="profile_info">
      <div class="d-flex justify-content-between flex-column flex-md-row">
        <p class="profile_name mr-md-3">{{ userData.firstname }}</p>
        <div>
          <a class="profile_chngpswd mr-4" @click="editProfileModal">
            <Icon class="mr-2" />
            <span>{{ $t('profile_info_edit_profile') }}</span>
          </a>
          <a class="profile_chngpswd" @click="changePasswordModal">
            <Icon :type="'change-password-key'" class="mr-2" />
            <span>{{ $t('profile_info_change_password') }}</span>
          </a>
        </div>
      </div>
      <hr class="my-4" />
      <div class="d-flex justify-content-md-between flex-column flex-md-row">
        <p class="col-lg-3 d-flex align-items-center align-items-md-start">
          <img
            src="../../assets/icon/icon-mail.svg"
            alt="icon-mail"
            class="mr-2 image"
          />
          <span>{{ userData.email }}</span>
        </p>
        <p
          class="col-lg-3 d-flex text-left text-md-center align-items-center align-items-md-start"
        >
          <img
            src="../../assets/icon/icon-school.svg"
            alt="icon-school"
            class="mr-2 image"
          />
          <span>
            {{ userData.university || $t('profile_info_empty_field') }}
          </span>
        </p>
        <p class="col-lg-3 d-flex align-items-center align-items-md-start">
          <img
            src="../../assets/icon/icon-briefcase.svg"
            alt="icon-briefcase"
            class="mr-2 image"
          />
          <span>
            {{ userData.occupation || $t('profile_info_empty_field') }}
          </span>
        </p>
      </div>
    </div>
    <div class="mt-4 profile_info">
      <h5 class="text-center text-md-left headline">
        {{ $t('profile_info_access_settings') }}
        <b-spinner v-if="isLoading" variant="success" small />
      </h5>
      <div class="d-flex flex-column flex-sm-row mt-4 mb-4 mb-lg-0">
        <div class="col-12 col-sm-4 d-flex flex-column px-2 px-md-3 px-lg-5">
          <img
            src="../../assets/profile/reading-a-book.svg"
            alt="book"
            class="mb-4"
          />
          <h6 class="mt-2">
            {{ $t('profile_info_redBook') }}
          </h6>

          <p class="profile_access_txt">
            {{ $t('profile_info_redBook_description') }}
          </p>
          <span
            v-if="userData.redBook === 'adminDenied'"
            class="accessDenied mb-3"
          >
            {{ $t('profile_info_access_denied') }}
          </span>
          <b-button
            v-if="
              userData.redBook === 'denied' ||
                userData.redBook === 'adminDenied'
            "
            class="success mt-auto align-self-center"
            @click="requestAccess('book=1')"
          >
            {{ $t('profile_info_btn_access') }}
          </b-button>
          <b-button
            v-if="userData.redBook === 'pending'"
            class="warning mt-auto align-self-center"
            disabled
          >
            {{ $t('profile_info_btn_pending') }}
          </b-button>
          <b-icon
            v-if="userData.redBook === 'allowed'"
            icon="check"
            class="icon"
          />
        </div>
        <div
          class="col-12 col-sm-4 d-flex flex-column pt-5 pt-md-0 px-2 px-md-3 px-lg-5"
        >
          <img
            src="../../assets/profile/entrepreneurs.svg"
            alt="entrepreneurs"
            class="mb-4"
          />
          <h6 class="mt-2">{{ $t('profile_info_editor') }}</h6>
          <p class="profile_access_txt">
            {{ $t('profile_info_editor_description') }}
          </p>
          <span
            v-if="userData.editor === 'adminDenied'"
            class="accessDenied mb-3"
          >
            {{ $t('profile_info_access_denied') }}
          </span>
          <b-button
            v-if="
              userData.editor === 'denied' || userData.editor === 'adminDenied'
            "
            class="success mt-auto align-self-center"
            @click="requestAccess('editor=1')"
          >
            {{ $t('profile_info_btn_access') }}
          </b-button>
          <b-button
            v-if="userData.editor === 'pending'"
            class="warning mt-auto align-self-center"
            disabled
          >
            {{ $t('profile_info_btn_pending') }}
          </b-button>
          <b-icon
            v-if="userData.editor === 'allowed'"
            icon="check"
            class="icon"
          />
        </div>
        <div
          class="col-12 col-sm-4 d-flex flex-column pt-5 pt-md-0 px-2 px-md-3 px-lg-5"
        >
          <img
            src="../../assets/profile/super-man.svg"
            alt="super_man"
            class="mb-4 mt-3 mt-sm-0"
          />
          <h6 class="mt-2">{{ $t('profile_info_admin') }}</h6>
          <p class="profile_access_txt">
            {{ $t('profile_info_admin_description') }}
          </p>
          <span
            v-if="userData.admin === 'adminDenied'"
            class="accessDenied mb-3"
          >
            {{ $t('profile_info_access_denied') }}
          </span>
          <b-button
            v-if="
              userData.admin === 'denied' || userData.admin === 'adminDenied'
            "
            class="success mt-auto align-self-center"
            @click="requestAccess('admin=1')"
          >
            {{ $t('profile_info_btn_access') }}
          </b-button>
          <b-button
            v-if="userData.admin === 'pending'"
            class="warning mt-auto align-self-center"
            disabled
          >
            {{ $t('profile_info_btn_pending') }}
          </b-button>
          <b-icon
            v-if="userData.admin === 'allowed'"
            icon="check"
            class="icon"
          />
        </div>
      </div>
    </div>
    <Modal
      :id-modal="'changePasswordModal'"
      :title="$t('profile_info_change_password')"
    >
      <template #modalBody>
        <ChangePasswordForm />
      </template>
    </Modal>
    <Modal
      :id-modal="'editProfileModal'"
      :title="$t('profile_info_edit_profile')"
    >
      <template #modalBody>
        <EditProfileForm />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Modal from '@/components/Modal.vue'
import ChangePasswordForm from '@/components/Profile/ChangePasswordForm.vue'
import EditProfileForm from '@/components/Profile/EditProfileForm.vue'
import Icon from '@/components/Icon.vue'

export default {
  components: {
    ChangePasswordForm,
    Modal,
    Icon,
    EditProfileForm
  },
  computed: {
    ...mapGetters(['userData', 'isLoading'])
  },
  methods: {
    ...mapActions(['requestAccess', 'changePassword']),
    changePasswordModal() {
      this.$root.$emit('bv::show::modal', 'changePasswordModal', '#btnShow')
    },
    editProfileModal() {
      this.$root.$emit('bv::show::modal', 'editProfileModal', '#btnShow')
    }
  },
  accessRequest(url) {
    this.requestAccess(url)
  }
}
</script>
<style lang="scss" scoped>
.profile_info {
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0px 4px 64px rgba(216, 231, 255, 0.15);
  border-radius: 4px;
  @media (max-width: 769px) {
    padding: 0.75rem 1.438rem;
  }
  @media (max-width: 426px) {
    padding: 1rem 0.5rem;
  }
  .image {
    @media (max-width: 426px) {
      width: 18px;
      height: 18px;
    }
  }
  .addHerb {
    background-color: #00b05a;
    border-radius: 8px;
    border: none;
    color: #fff;
    text-decoration: none;
    &:hover {
      background-color: #18bd6d;
    }
  }
  .profile_name {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
  }
  .profile_chngpswd {
    color: #00b05a;
    line-height: 1.375rem;
    cursor: pointer;
    font-weight: 500;
    span {
      vertical-align: middle;
    }
    img {
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: middle;
    }
  }
  .icon {
    color: #00b05a;
    font-size: 3rem;
    margin: auto;
  }
  .accessDenied {
    color: #ee717c;
  }
}
.headline {
  font-size: 1.125rem;
}
.profile_access_txt {
  font-size: 0.875rem;
  margin: 1rem 0;
}
</style>
