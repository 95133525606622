<template>
  <b-form>
    <b-form-group
      id="input-group-oldPassword"
      :label="$t('profile_info_change_oldPassword')"
      label-for="oldPassword"
      class="text-left"
    >
      <b-form-input
        id="oldPassword"
        v-model.trim="$v.form.oldPassword.$model"
        type="password"
        :state="validateState('oldPassword')"
      />
      <template
        v-if="$v.form.oldPassword.$dirty && $v.form.oldPassword.$invalid"
      >
        <b-form-text
          v-if="!$v.form.oldPassword.alphaNumRegex"
          text-variant="danger"
        >
          {{ $t('profile_info_confirm_password_alphanum_error') }}
        </b-form-text>
        <b-form-text
          v-if="!$v.form.oldPassword.minLength"
          text-variant="danger"
        >
          {{ $t('profile_info_confirm_password_min_length_error') }}
        </b-form-text>
        <b-form-text v-if="!$v.form.oldPassword.required" text-variant="danger">
          {{ $t('profile_info_confirm_password_required_error') }}
        </b-form-text>
      </template>
    </b-form-group>
    <b-form-group
      id="input-group-newPassword"
      :label="$t('profile_info_change_newPassword')"
      label-for="newPassword"
      class="text-left"
    >
      <b-form-input
        id="newPassword"
        v-model.trim="$v.form.newPassword.$model"
        type="password"
        :state="
          form.confirmPassword.length
            ? $v.form.confirmPassword.sameAsNewPassword
            : validateState('newPassword')
        "
      />
      <template
        v-if="$v.form.newPassword.$dirty && $v.form.newPassword.$invalid"
      >
        <b-form-text
          v-if="!$v.form.newPassword.alphaNumRegex"
          text-variant="danger"
        >
          {{ $t('profile_info_confirm_password_alphanum_error') }}
        </b-form-text>
        <b-form-text
          v-if="!$v.form.newPassword.minLength"
          text-variant="danger"
        >
          {{ $t('profile_info_confirm_password_min_length_error') }}
        </b-form-text>
        <b-form-text v-if="!$v.form.newPassword.required" text-variant="danger">
          {{ $t('profile_info_confirm_password_required_error') }}
        </b-form-text>
      </template>
    </b-form-group>
    <b-form-group
      id="input-group-confirm-newPassword"
      :label="$t('profile_info_confirm_newPassword')"
      label-for="newPassword"
      class="text-left"
    >
      <b-form-input
        id="confirmPassword"
        v-model.trim="$v.form.confirmPassword.$model"
        type="password"
        :state="validateState('confirmPassword')"
      />
      <template
        v-if="
          $v.form.confirmPassword.$dirty && $v.form.confirmPassword.$invalid
        "
      >
        <b-form-text
          v-if="!$v.form.confirmPassword.alphaNumRegex"
          text-variant="danger"
        >
          {{ $t('profile_info_confirm_password_alphanum_error') }}
        </b-form-text>
        <b-form-text
          v-if="!$v.form.confirmPassword.minLength"
          text-variant="danger"
        >
          {{ $t('profile_info_confirm_password_min_length_error') }}
        </b-form-text>
        <b-form-text
          v-if="!$v.form.confirmPassword.required"
          text-variant="danger"
        >
          {{ $t('profile_info_confirm_password_required_error') }}
        </b-form-text>
      </template>
    </b-form-group>
    <template v-if="$v.form.$anyError">
      <b-form-text
        v-if="
          $v.form.newPassword.$dirty && !$v.form.newPassword.sameAsOldPassword
        "
        text-variant="danger"
      >
        {{ $t('profile_info_change_newPassword_error') }}
      </b-form-text>
      <b-form-text
        v-if="
          $v.form.confirmPassword.$dirty &&
            !$v.form.confirmPassword.sameAsNewPassword
        "
        text-variant="danger"
      >
        {{ $t('profile_info_confirm_newPassword_error') }}
      </b-form-text>
    </template>
    <hr />
    <div class="ModalBtn">
      <b-button class="mr-3 ml-auto success" @click="passwordChange">
        {{ $t('main_page_sub_nav_save_result') }}
      </b-button>
    </div>
  </b-form>
</template>
<script>
import { mapActions } from 'vuex'
import {
  required,
  minLength,
  helpers,
  sameAs,
  not
} from 'vuelidate/lib/validators'
const alphaNumRegex = helpers.regex(
  'alphaNumRegex',
  /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/
)
export default {
  data() {
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }
    }
  },
  validations: {
    form: {
      oldPassword: {
        required,
        sameAsOldPassword: not(sameAs('newPassword')),
        alphaNumRegex,
        minLength: minLength(6)
      },
      newPassword: {
        required,
        alphaNumRegex,
        minLength: minLength(6),
        sameAsOldPassword: not(sameAs('oldPassword'))
      },
      confirmPassword: {
        required,
        alphaNumRegex,
        minLength: minLength(6),
        sameAsNewPassword: sameAs('newPassword')
      }
    }
  },
  methods: {
    ...mapActions(['changePassword']),
    passwordChange() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.changePassword(this.form).then(success => {
        if (success) {
          this.$root.$emit('bv::hide::modal', 'changePasswordModal', '#btnShow')
        }
      })
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v.form[name]
      if (!$model.length && !$invalid) return null
      return $dirty ? !$error : null
    }
  }
}
</script>
<style lang="scss" scoped>
.ModalBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
}
hr {
  margin: 0 -15px;
}
</style>
