<template>
  <b-form>
    <b-form-group
      id="input-group-email"
      :label="$t('profile_users_table_email')"
      label-for="email"
      class="text-left"
    >
      <b-form-input
        id="email"
        v-model.trim="form.email"
        type="email"
        disabled
      />
    </b-form-group>
    <b-form-group
      id="input-group-name"
      :label="$t('register_page_input_name_label')"
      label-for="name"
      class="text-left"
    >
      <b-form-input
        id="name"
        v-model.trim="$v.form.name.$model"
        type="text"
        :state="validateState('name')"
      />
      <b-form-text
        v-if="$v.form.name.$dirty && !$v.form.name.required"
        text-variant="danger"
      >
        {{ $t('profile_info_confirm_password_required_error') }}
      </b-form-text>
    </b-form-group>
    <b-form-group
      id="input-group-confirm-university"
      :label="$t('profile_info_confirm_university')"
      label-for="university"
      class="text-left"
    >
      <b-form-input
        id="university"
        v-model.trim="$v.form.university.$model"
        type="text"
        :state="validateState('university')"
      />
      <b-form-text
        v-if="$v.form.university.$dirty && !$v.form.university.required"
        text-variant="danger"
      >
        {{ $t('profile_info_confirm_password_required_error') }}
      </b-form-text>
    </b-form-group>
    <b-form-group
      id="input-group-confirm-occupation"
      :label="$t('profile_info_confirm_occupation')"
      label-for="occupation"
      class="text-left"
    >
      <b-form-input
        id="confirmPassword"
        v-model.trim="$v.form.occupation.$model"
        :state="validateState('occupation')"
        type="text"
      />
      <b-form-text
        v-if="$v.form.occupation.$dirty && !$v.form.occupation.required"
        text-variant="danger"
      >
        {{ $t('profile_info_confirm_password_required_error') }}
      </b-form-text>
    </b-form-group>
    <hr />
    <div class="ModalBtn">
      <b-button class="mr-3 ml-auto success" @click="profileEdit">
        {{ $t('main_page_sub_nav_save_result') }}
      </b-button>
    </div>
  </b-form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        university: '',
        occupation: ''
      }
    }
  },
  validations: {
    form: {
      name: { required },
      university: { required },
      occupation: { required }
    }
  },
  computed: mapGetters(['userData']),
  mounted() {
    this.form = { ...this.userData, name: this.userData.firstname }
  },
  methods: {
    ...mapActions(['editProfile']),
    async profileEdit() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.editProfile(this.form).then(success => {
        if (success) {
          this.$root.$emit('bv::hide::modal', 'editProfileModal', '#btnShow')
        }
      })
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v.form[name]
      if (!$model.length && !$invalid) return null
      return $dirty ? !$error : null
    }
  }
}
</script>
<style lang="scss" scoped>
.ModalBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
}
hr {
  margin: 0 -15px;
}
</style>
